import React, { useState, useEffect, useCallback } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { ItemNavGroup } from "../../form/ItemNavGroup";
import PDFBarcode from "../../foundation/PDFBarcode";
import {ILicenceOrder, IUserModel} from "../../../api/models";
import judoLogo from '../../../theme/editions/judoaustria/images/judo_logo.png';
import { BackButton } from "../../form";
import { IPageLinkProps } from "../../page.props";
import { TabLayout } from "../../foundation/tab_layout";
import { faIdCard, faList, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import StatisticsComponent from './licence_statistic';
import MemberListComponent from './licence_member_list';
import { JudocardService } from "../../../api/judocard";
import { Spinner } from "../../foundation/spinner";
import {useParamRole} from "../../useParam";
import { pdfStyles } from './MemberCardsPDFStyles'; // Import PDF styles
import './MemberCardsPDFViewer.scss'; // Import component styles
import style from '../../form/Input.module.scss';
import classNames from "classnames";

const API_BASE_URL = process.env.REACT_APP_JAMA_DOMAIN_V2 || 'http://127.0.0.1:8081';

const BELTCOLORS = JSON.parse(`[{
  "rot": { "name": "rot", "colors": ["#ff0000"] },
  "blau": { "name": "blau", "colors": ["#0000ff"] },
  "gelb": { "name": "gelb", "colors": ["#FFFB00"] },
  "braun": { "name": "braun", "colors": ["#7e4f40"] },
  "grün": { "name": "grün", "colors": ["#00cc30"] },
  "weiss": { "name": "weiss", "colors": ["#ffffff"] },
  "orange": { "name": "orange", "colors": ["#ffaa00"] },
  "schwarz": { "name": "schwarz", "colors": ["#000000"] },
  "blau_braun": { "name": "blau/braun", "colors": ["#0000ff", "#7e4f40"] },
  "grün_blau": { "name": "grün/blau", "colors": ["#00cc30", "#0000ff"] },
  "weiss_gelb": { "name": "weiss/gelb", "colors": ["#ffffff", "#FFFB00"] },
  "gelb_orange": { "name": "gelb/orange", "colors": ["#FFFB00", "#ffaa00"] },
  "grüen_blau": { "name": "grün/blau", "colors": ["#00cc30", "#0000ff"] },
  "orange_grün": { "name": "orange/grün", "colors": ["#ffaa00", "#00cc30"] },
  "rot_weiß_rot": { "name": "rot/weiss/rot", "colors": ["#ff0000", "#ffffff", "#ff0000"] },
  "weiss,1.Sonne": { "name": "weiss, 1.Sonne", "colors": ["#ffffff"] },
  "weiss,2.Sonne": { "name": "weiss, 2.Sonne", "colors": ["#ffffff"] },
  "weiss,3.Sonne": { "name": "weiss, 3.Sonne", "colors": ["#ffffff"] }
}]`) as Array<{[key: string]: { name: string, colors: string[] }}>;


// Define styles for PDF using the imported styles
const styles = StyleSheet.create(pdfStyles);

// Interface for member data
interface IMember {
    mgId: number;
    surname: string;
    firstname: string;
    birthdate: string;
    orgName?: string;
    orgId?: number;
    errorCode?: string | null;
    nationality: string | null;
    ordered?: boolean;
    uuid: string;
    gender: string;
    trainingBelt?: string;
    judocardYear?: number;
}

// Helper function to format the birthdate
const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE');
};

// Helper function to split members into chunks of 10 for pagination
const chunkArray = (array: any[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

// The PDF document component with pagination
const MemberCardsPDF: React.FC<{ members: IMember[], user: IUserModel }> = ({ members, user }) => {

    const getBeltColors = (beltName: string): string[] => {
        const normalizedBeltName = beltName.replace(/\//g, '_');
        console.log('Looking for belt:', normalizedBeltName);

        const beltColorObject = BELTCOLORS[0];
        console.log('Belt color object keys:', Object.keys(beltColorObject));

        if (beltColorObject && beltColorObject[normalizedBeltName]) {
            console.log('Found belt by key:', normalizedBeltName, beltColorObject[normalizedBeltName]);
            return beltColorObject[normalizedBeltName].colors || [];
        }

        for (const key in beltColorObject) {
            if (beltColorObject[key].name === beltName) {
                console.log('Found belt by name:', beltName, beltColorObject[key]);
                return beltColorObject[key].colors || [];
            }
        }

        console.log('Belt not found:', normalizedBeltName);
        return [];
    };


    const memberChunks = chunkArray(members, 10);

    return (
        <Document>
            {memberChunks.map((chunk, pageIndex) => (
                <Page key={pageIndex} size="A4" style={styles.page}>
                    {chunk.map((member, index) => {
                        const barcodeValue = `JAMA${member.mgId}`;

                        return (
                            <View key={index} style={styles.card}>
                                <View style={styles.cardHeader}>
                                    <Text style={styles.cardTitle}>JUDOCARD 2025</Text>
                                </View>

                                <View style={styles.nameSection}>
                                    <Text style={styles.name}>{member.surname} {member.firstname}</Text>
                                </View>

                                <View style={styles.contentContainer}>
                                    <View style={styles.detailsContainer}>
                                        <View style={styles.infoRow}>
                                            <Text style={styles.label}>Geburtsdatum:</Text>
                                            <Text style={styles.value}>{formatDate(member.birthdate)} | {member.gender}</Text>
                                        </View>

                                        <View style={styles.infoRow}>
                                            <Text style={styles.label}>JAMA-ID / Nat.   </Text>
                                            <Text style={styles.value}>{member.mgId} / {member.nationality}</Text>
                                        </View>

                                        <View style={styles.infoRow}>
                                            <Text style={styles.label}>Verein:</Text>
                                            <Text style={styles.value}>{member.orgName || member.orgId}</Text>
                                        </View>

                                        <View style={styles.infoRow}>
                                            <View style={styles.valueBelt}>
                                                {member.trainingBelt && (() => {
                                                    const colors = getBeltColors(member.trainingBelt);

                                                    if (colors.length === 0) return null;

                                                    return (
                                                        <View style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            height: 12,
                                                            marginTop: 2,
                                                            border: '0.5pt solid black'
                                                        }}>
                                                            {colors.map((color, index) => (
                                                                <View
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor: color,
                                                                        height: '100%',
                                                                        width: `${100 / colors.length}%`
                                                                    }}
                                                                />
                                                            ))}
                                                        </View>
                                                    );
                                                })()}
                                            </View>
                                        </View>

                                        <View style={styles.barcodeContainer}>
                                            <PDFBarcode value={barcodeValue} />
                                            <Text style={styles.footerText}>{barcodeValue}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.imageContainer}>
                                        <Image
                                            src={`${API_BASE_URL}/api/v2/web/profile/image/${member.uuid}`}
                                            style={styles.memberImage}
                                            cache={false}
                                        />
                                    </View>
                                </View>
                            </View>
                        );
                    })}
                </Page>
            ))}
        </Document>
    );
};

// The component to render in your application
const MemberCardsPDFViewer: React.FC<{ members: IMember[], user: IUserModel}> = ({ members, user }) => {
    const [pdfLoading, setPdfLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    // Filter members based on search query
    const filteredMembers = members.filter(member => {
        const fullName = `${member.surname} ${member.firstname}`.toLowerCase();
        const memberId = `${member.mgId}`.toLowerCase();
        const searchLower = searchQuery.toLowerCase();

        return fullName.includes(searchLower) ||
            memberId.includes(searchLower) ||
            (member.orgName && member.orgName.toLowerCase().includes(searchLower));
    });

    // Simulate PDF rendering completion after component mounts
    useEffect(() => {
        // Set a timeout to hide the spinner after a reasonable delay
        const timer = setTimeout(() => {
            setPdfLoading(false);
        }, 2500); // 2.5 seconds

        return () => clearTimeout(timer);
    }, [members]); // Re-run when members data changes

    // Handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    return (
        <ItemNavGroup label="Mitgliedskarten drucken">
            <div className="search-container">
                <input
                    type="text"
                    className={classNames(style.input, "form-control search-input")}
                    placeholder="Suche nach Name, ID oder Verein..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <small className="form-text text-muted">
                    {filteredMembers.length} von {members.length} Mitgliedern angezeigt
                </small>
            </div>

            <div className="pdf-viewer-container">
                {pdfLoading && (
                    <div className="loading-overlay">
                        <div className="spinner-container">
                            <Spinner size="large" />
                            <div className="spinner-text">
                                Generating PDF... This may take a moment.
                            </div>
                        </div>
                    </div>
                )}

                <PDFViewer className="pdf-document">
                    <MemberCardsPDF members={filteredMembers} user={user} />
                </PDFViewer>
            </div>
        </ItemNavGroup>
    );
};


const JudoCardPrintingTab: React.FC<{ user: IUserModel }> = ({ user }) => {
    // State for managing members data and loading status
    const [members, setMembers] = useState<IMember[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [dataFetched, setDataFetched] = useState<boolean>(false);

    const role = useParamRole(user);

    // Function to fetch judocard data
    const fetchJudocards = useCallback(async () => {
        // Don't fetch again if we already have data
        if (dataFetched) return;

        setLoading(true);
        try {
            // Get the organization ID from the role
            const orgId = role?.orgId || 0;

            if (!orgId) {
                setError('No organization ID found');
                setLoading(false);
                return;
            }

            // Fetch ALL judocards using our helper method with specific year (2025)
            const judocards = await JudocardService.getAllTrainerJudocards(orgId, 100, 2025);

            // Type-safe approach: cast the judocards as IMember[]
            setMembers(judocards as unknown as IMember[]);
            setDataFetched(true);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching judocards:', err);
            setError('Failed to fetch judocards. Please try again later.');
            setLoading(false);
        }
    }, [role, dataFetched]);

    // Fetch data when component mounts - this component only mounts when the tab is selected
    useEffect(() => {
        fetchJudocards();
    }, [fetchJudocards]);

    if (loading) {
        return (
            <ItemNavGroup label="Mitgliedskarten drucken">
                <div className="loading-container">
                    {error ? (
                        <div className="text-danger">{error}</div>
                    ) : (
                        <Spinner size="large" />
                    )}
                </div>
            </ItemNavGroup>
        );
    }

    return (
        <MemberCardsPDFViewer members={members} user={user} />
    );
};

export const LicenceDetailPrintCards: React.FC<IPageLinkProps & {
    filteredOrders?: ILicenceOrder[];
    dateFrom?: Date | null;
    dateTo?: Date | null;
}> = ({ user, gotoUrl, profile, refresh, setRefresh, filteredOrders = [], dateFrom, dateTo }) => {
    return (
        <>
            <TabLayout
                items={[
                    {
                        label: 'Sta\u00ADtistik',
                        icon: faList,
                        component: <StatisticsComponent
                            user={user}
                            filteredOrders={filteredOrders}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                        />,
                    },
                    {
                        label: 'Karten\u00ADdruck',
                        icon: faIdCard,
                        component: <JudoCardPrintingTab user={user} />,
                    },
                ]}
            />
        </>
    );
};